<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n
              code="unitTemplate.edit.title"
              v-if="isEditing"
            ></app-i18n>
            <app-i18n code="unitTemplate.new.title" v-else></app-i18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('unitTemplate.fields.name') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.title.en"
                    :placeholder="i18n('unitTemplate.fields.english')"
                  />
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-ar"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.title.ar"
                    :placeholder="i18n('unitTemplate.fields.arabic')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('unitTemplate.fields.description') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-en"
                    :rows="4"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.en"
                    :placeholder="i18n('unitTemplate.fields.english')"
                  ></textarea>
                </div>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <textarea
                    id="description-ar"
                    :rows="4"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.description.ar"
                    :placeholder="i18n('unitTemplate.fields.arabic')"
                  ></textarea>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('unitTemplate.fields.area') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.area"
                    :placeholder="i18n('unitTemplate.fields.area')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('unitTemplate.fields.rooms') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.rooms"
                    :placeholder="i18n('unitTemplate.fields.rooms')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('unitTemplate.fields.bathrooms') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <input
                    id="title-en"
                    type="text"
                    class="
                      form-control
                      w-full
                      dark:bg-dark-9 dark:placeholder-white
                    "
                    v-model="model.bathrooms"
                    :placeholder="i18n('unitTemplate.fields.bathrooms')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('unitTemplate.fields.unitType') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <TomSelect
                    v-model="model.unitType"
                    class="w-34 sm:w-32 mt-2 sm:mt-0 dark:bg-dark-9"
                  >
                    <option
                      v-for="(type, index) in unitTypeOptions"
                      :key="index"
                      :value="type"
                    >
                      {{ i18n(`common.${type}`) }}
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('unitTemplate.fields.finish') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <TomSelect
                    v-model="model.finish"
                    class="w-34 sm:w-32 mt-2 sm:mt-0 dark:bg-dark-9"
                  >
                    <option
                      v-for="(finish, index) in finishOptions"
                      :key="index"
                      :value="finish"
                    >
                      {{ i18n(`common.${finish}`) }}
                    </option>
                  </TomSelect>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2 flex items-center">
                  <input
                    id="garage"
                    type="checkbox"
                    class="form-check-input border mr-2 ml-2"
                    v-model="model.garage"
                  />
                  <label
                    class="
                      text-lg
                      font-medium
                      cursor-pointer
                      select-none
                      xl:text-black
                    "
                    for="garage"
                    >{{ i18n('unitTemplate.fields.garage') }}</label
                  >
                </div>
              </div>
              <div
                class="grid grid-cols-12 gap-x-5 mt-5 items-center"
                v-if="isEditing"
              >
                <h4
                  class="
                    text-lg
                    font-medium
                    leading-none
                    items-center
                    mr-2
                    lg:col-span-2
                  "
                >
                  {{ i18n('unitTemplate.fields.images') }}
                </h4>
                <div class="lg:col-span-8 col-span-12 lg:mt-0 mt-2">
                  <div class="relative" v-if="isEditing">
                    <div class="flex gap-0 flex-wrap" v-if="imagesUrls">
                      <div
                        class="relative h-32 w-36"
                        v-for="(image, index) in imagesUrls"
                        :key="index"
                      >
                        <img :src="image" class="object-cover h-full w-full" />
                        <img
                          src="/images/deleteIcon.png"
                          alt=""
                          class="
                            delete-icon
                            w-12
                            h-12
                            absolute
                            bottom-0
                            left-0
                            cursor-pointer
                          "
                          @click="deleteImage(index)"
                        />
                      </div>
                    </div>
                    <Uploader
                      ref="uploaderImagesAdd"
                      message="common.addMoreImages"
                      :multiple="true"
                      :id="id"
                    ></Uploader>
                  </div>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-32
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import Uploader from '@/views/projects/uploader.vue'

export default {
  props: {
    id: {
      type: String,
      required: false
    },
    projectId: {
      type: String,
      required: false
    }
  },
  components: {
    Uploader
  },
  data() {
    return {
      unitTypeOptions: ['apartment', 'villa', 'benthouse'],
      finishOptions: ['noFinish', 'semiFinish', 'lux', 'superLux', 'ultraLux'],
      model: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'template/form/record',
      findLoading: 'template/form/findLoading',
      saveLoading: 'template/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    },
    filesToSaveImagesAdd() {
      return this.$refs.uploaderImagesAdd?.filesToSave || []
    },
    filesToSaveImagesNew() {
      return this.$refs.uploaderImagesNew?.filesToSave || []
    },
    imagesUrls() {
      return this.model && this.model.images && this.model.images.length > 0
        ? this.model.images
        : null
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.template')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.template')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = this.record || {
      title: {
        en: '',
        ar: ''
      },
      area: '',
      rooms: '',
      bathrooms: '',
      description: {
        ar: '',
        en: ''
      },
      finish: '',
      garage: false,
      projectId: '',
      unitType: ''
    }
    if (!this.isEditing) {
      this.model.unitType = this.unitTypeOptions[0]
      this.model.finish = this.finishOptions[0]
    }
  },
  methods: {
    ...mapActions({
      doNew: 'template/form/doNew',
      doFind: 'template/form/doFind',
      doCreate: 'template/form/doAdd',
      doUpdate: 'template/form/doUpdate'
    }),
    deleteImage(index) {
      this.model.images.splice(index, 1)
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.title.en.length == 0 || this.model.title.ar.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }

      if (
        this.model.description.en.length == 0 ||
        this.model.description.ar.length == 0
      ) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (this.model.unitType.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (this.model.finish.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (this.model.area.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (isNaN(this.model.area)) {
        this.errorMessage = 'notifications.validNumbers.area'
        return false
      }
      if (this.model.rooms.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (isNaN(this.model.rooms)) {
        this.errorMessage = 'notifications.validNumbers.number'
        return false
      }
      if (this.model.bathrooms.length == 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (isNaN(this.model.bathrooms)) {
        this.errorMessage = 'notifications.validNumbers.number'
        return false
      }
      if (this.isEditing) {
        if (
          !(
            this.imagesUrls ||
            this.filesToSaveImagesAdd.length !== 0 ||
            this.filesToSaveImagesNew.length !== 0
          )
        ) {
          this.errorMessage = 'notifications.create.popup.emptyFields'
          return false
        }
      }
      return true
    },
    doCancel() {
      this.$router.back()
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      if (this.isEditing) {
        const {
          title,
          description,
          finish,
          unitType,
          area,
          rooms,
          bathrooms,
          garage
        } = this.model
        const values = {
          title,
          description,
          unitType,
          finish,
          area: parseFloat(area),
          rooms: parseInt(rooms),
          bathrooms: parseInt(bathrooms),
          garage,
          images: this.imagesUrls
            ? [...this.imagesUrls, ...this.filesToSaveImagesAdd]
            : this.filesToSaveImagesAdd
        }
        await this.doUpdate({
          id: this.id,
          values
        })
      } else {
        const { area, rooms, bathrooms } = this.model
        await this.doCreate({
          ...this.model,
          projectId: this.projectId,
          area: parseFloat(area),
          rooms: parseInt(rooms),
          bathrooms: parseInt(bathrooms)
        })
      }
    }
  }
}
</script>
  <style lang="scss">
.tom-select {
  .ts-input,
  .ts-dropdown {
    width: 12rem !important;
  }
}
.dropzone {
  .dz-preview {
    .dz-details {
      font-size: 12px !important;
      padding: 0em 1em !important;
    }
    .dz-image {
      width: 80px !important;
      height: 80px !important;
    }
  }
}
</style>
